import React from 'react';

import ActiveGifts from './components/ActiveGifts';
import RealizedGifts from './components/RealizedGifts';
import styles from './gift.module.scss';

const Gifts: React.FC<{isRealized: boolean}> = ({isRealized}) => {
    return <div className={styles.list}>{isRealized ? <RealizedGifts /> : <ActiveGifts />}</div>;
};

export default Gifts;
