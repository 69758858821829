import React from 'react';
import {Modal, Button} from 'antd';

const ConfirmGiftChangeStatusToFreeModal = ({onClose, onSubmit}: any) => {
    return (
        <Modal
            visible={true}
            closable={false}
            title="Подтверждение смены статуса"
            footer={[
                <Button key="1" color="secondary" onClick={onClose} className="custom-btn custom-btn__cancel">
                    Скасувати
                </Button>,
                <Button key="2" color="primary" onClick={onSubmit} className="custom-btn custom-btn__consent">
                    Змінити статус
                </Button>,
            ]}
        >
            <div>
                Якщо ви це зробите, всі дані Виконавця зітруться.
                <br />
                Ви впевнені, що хочете змінити <strong>статус на Вільний?</strong>
            </div>
        </Modal>
    );
};

export default ConfirmGiftChangeStatusToFreeModal;
