import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Button, Col, Row} from 'antd';
import ReactSnowFall from 'react-snowfall';

import useShowModalsActions from 'hooks/useShowModalsActions';
import {Gift} from 'types/index';

import girl from 'images/bg/photo_girl.png';

import * as actions from './actions';
import styles from './main.module.scss';

const Index = (): JSX.Element | null => {
    const {showCreateGiftModal} = useShowModalsActions();
    const dispatch = useDispatch();
    const showCreateGiftModalHandler = useCallback(() => {
        showCreateGiftModal((gift: Gift): void => {
            dispatch(actions.createGift(gift));
        });
    }, [dispatch, showCreateGiftModal]);
    return (
        <div className={styles.wrap}>
            <ReactSnowFall />
            <div className={styles.item}>
                <Row type="flex" align="middle" justify="space-between" className={styles.row}>
                    <Col sm={24} md={12}>
                        <img src={girl} className={styles.img} alt="girl" />
                    </Col>
                    <Col sm={24} md={12}>
                        <div className="description">
                            <div className="description__title">Здійсни мрію дитини — Стань Святим Миколаєм!</div>
                            Щороку фонд «Доброта та довіра» вітає особливих діток із&nbsp;Днем Святого Миколая
                            <div className="description__button">
                                <Button onClick={showCreateGiftModalHandler} className="custom-btn custom-btn__request">
                                    ПОДАТИ ЗАЯВКУ
                                </Button>
                                <Link to="/gifts" className="ant-btn custom-btn custom-btn__action ant-btn-primary">
                                    СТАТИ ЧАРІВНИКОМ
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Index;
