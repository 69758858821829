import {Switch} from 'antd/lib';
import React from 'react';

interface OwnProps {
    field: any;
    form: any;
}

const ApproveGiftSwitcher = ({field, form}: OwnProps): JSX.Element | null => {
    const isApproved = Boolean(form.values[field.name]);
    const confirmRequestText = isApproved ? <h5>Участь у акції</h5> : <h4>Підтвердьте заявку на участь у акції</h4>;
    const onChangeHandler = (): void => {
        form.setFieldValue(field.name, !isApproved);
    };
    return (
        <div className="confirm__request">
            <div className="confirm__request_switch">
                {confirmRequestText}
                <Switch checked={isApproved} onChange={onChangeHandler} />
            </div>
            {!isApproved && (
                <p>Поки ви не підтвердите завку на участь в акції, її не буде додано до списку для бронювання.</p>
            )}
        </div>
    );
};

export default ApproveGiftSwitcher;
