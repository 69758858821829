import React from 'react';

import styles from './styles.module.scss';

const CharityEventInfo: React.FC = () => {
    return (
        <div>
            <h2 className={styles.container}>Діти</h2>
        </div>
    );
};

export default CharityEventInfo;
