import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row} from 'antd';

import {Gift} from 'types/index';
import ChildrenTitle from 'components/ChildrenTitle';

import BreadCrumbs from 'pages/Gifts/components/BreadCrumbs';
import GiftItem from 'pages/Gifts/components/GiftItem';
import Pagination from 'pages/Gifts/components/Pagination';

import {getListGifts, getPagination, getSearch} from 'pages/Gifts/selectors';
import {fetchRealizedGifts} from 'pages/Gifts/actions/guestActions';
import {changePaginationPage} from 'pages/Gifts/actions/commonActions';
import EventInfo from 'pages/Gifts/components/RealizedGifts/components/EventInfo';

const RealizedGifts = (): JSX.Element => {
    const gifts = useSelector(getListGifts);
    const searchParams = useSelector(getSearch);
    const pagination = useSelector(getPagination);
    const dispatch = useDispatch();
    const changePaginationPageHandler = useCallback(
        (nextPage: number) => {
            dispatch(changePaginationPage(nextPage));
        },
        [dispatch],
    );
    useEffect(() => {
        dispatch(fetchRealizedGifts({searchParams, pagination: {page: pagination.page, size: pagination.size}}));
    }, [dispatch, searchParams, pagination.page, pagination.size]);
    return (
        <div>
            <BreadCrumbs />
            <EventInfo giftsCount={pagination.itemsCount} />
            <ChildrenTitle />
            <Row className="gift-list-row" type="flex">
                {gifts.map((gift: Gift) => (
                    <Col lg={6} md={12} xs={24} key={gift._id}>
                        <GiftItem gift={gift} isAdmin={false} />
                    </Col>
                ))}
            </Row>
            {gifts.length > 0 && (
                <Pagination
                    allPages={pagination.pagesCount}
                    currentPage={pagination.page}
                    onPageChange={changePaginationPageHandler}
                />
            )}
        </div>
    );
};

export default RealizedGifts;
