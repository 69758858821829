import React from 'react';
import {Modal, Button} from 'antd';

const CreationGiftDuplicateError = ({onSubmit}: any): JSX.Element => {
    return (
        <Modal
            visible={true}
            closable={false}
            title="Дитина вже зареєстрована в акції"
            footer={[
                <div key="2" />,
                <Button key="1" color="primary" className="custom-btn custom-btn__remove" onClick={onSubmit}>
                    OK
                </Button>,
            ]}
        >
            <div>Дитина вже бере участь в акції. Подати заявку повторно неможливо.</div>
        </Modal>
    );
};

export default CreationGiftDuplicateError;
