import React from 'react';
import {Col, Layout, Row} from 'antd';

import logo_br from 'images/icon/logo_br.png';

import styles from './footer.module.scss';

const Footer: React.FC = () => {
    const viberLink = (
        <a className="social-link" href="viber://chat/?number=%2B380972397270">
            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.45551 7.26023C8.28041 6.86625 7.92259 6.5539 7.49233 6.58152C6.14458 6.66804 5.31177 7.90609 4.93582 9.21318C4.50363 10.7158 5.00299 12.3082 5.88703 13.5979C6.7122 14.8016 7.76599 15.8744 8.94948 16.7424C10.5826 17.9402 12.7326 18.7805 14.4714 17.742C15.3657 17.2079 16.1403 16.4094 16.3405 15.5381C16.4409 15.1011 16.1231 14.713 15.715 14.5273L13.6824 13.6028C13.4105 13.4792 13.0551 13.5403 12.8031 13.7006C12.3655 13.9789 11.7857 14.3115 11.3192 14.085C10.1773 13.5305 9.42055 12.8151 8.87398 11.644C8.65597 11.1769 8.96527 10.5931 9.23779 10.1555C9.40542 9.88638 9.4605 9.52147 9.33173 9.23173L8.45551 7.26023ZM7.42484 7.73175L8.25249 9.59395C8.10886 9.82682 7.91613 10.1627 7.79404 10.5385C7.66644 10.9314 7.56637 11.522 7.84699 12.1233C8.51346 13.5513 9.47008 14.4469 10.8241 15.1045C11.4435 15.4052 12.0514 15.289 12.4439 15.1527C12.803 15.0279 13.1268 14.836 13.3491 14.6963L15.1537 15.5171C14.9678 15.9159 14.5338 16.3846 13.8903 16.769C13.3394 17.098 12.6885 17.1594 11.9351 16.9788C11.1676 16.7947 10.3588 16.3706 9.61975 15.8286C8.53442 15.0326 7.57156 14.0508 6.82182 12.9571C6.04975 11.8308 5.71919 10.5897 6.025 9.52645C6.341 8.42776 6.88031 7.84636 7.42484 7.73175Z"
                    fill="#092F46"
                />
                <path
                    d="M14.349 11.032C14.4459 11.4003 14.1428 11.7314 13.762 11.7314C13.467 11.7314 13.2166 11.5277 13.1008 11.2565C12.8142 10.5851 12.2806 10.0515 11.6091 9.76489C11.3379 9.64911 11.1342 9.39872 11.1342 9.10376C11.1342 8.72293 11.4652 8.41989 11.8335 8.51675C13.0787 8.84418 14.0219 9.78835 14.349 11.032Z"
                    fill="#092F46"
                />
                <path
                    d="M16.4483 11.8556C16.8519 11.8556 17.165 11.4981 17.0684 11.1061C16.4569 8.62388 14.4902 6.65722 12.0079 6.04576C11.616 5.94922 11.2585 6.26226 11.2585 6.66589C11.2585 6.98735 11.4896 7.25891 11.7991 7.34579C13.7121 7.88278 15.2313 9.40199 15.7684 11.315C15.8553 11.6245 16.1268 11.8556 16.4483 11.8556Z"
                    fill="#092F46"
                />
                <path
                    d="M19.573 11.3178C19.5852 11.6724 19.2902 11.9517 18.9355 11.9517C18.5547 11.9517 18.2553 11.6312 18.2355 11.251C18.0646 7.95557 15.3507 5.24161 12.0553 5.07066C11.675 5.05093 11.3546 4.75149 11.3546 4.37071C11.3546 4.01599 11.6339 3.72097 11.9884 3.73323C16.0623 3.87414 19.4321 7.24404 19.573 11.3178Z"
                    fill="#092F46"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.409 21.1546C20.3795 20.6091 22.6052 18.1778 22.9673 15.1795C23.1187 13.9264 23.1982 12.6287 23.1982 11.2994C23.1982 9.97014 23.1187 8.67244 22.9673 7.41936C22.6052 4.42102 20.3795 1.98979 17.409 1.44431C15.6823 1.12721 13.8421 0.956055 11.9325 0.956055C10.0228 0.956055 8.18267 1.12721 6.45589 1.44431C3.48544 1.98979 1.25974 4.42102 0.897598 7.41936C0.746248 8.67244 0.666748 9.97014 0.666748 11.2994C0.666748 12.6287 0.746248 13.9264 0.897598 15.1795C1.20703 17.7414 2.87697 19.8893 5.20953 20.8006C5.48185 20.9069 5.67372 21.1621 5.67372 21.4544V24.9835C5.67372 25.6096 6.45875 25.8904 6.85583 25.4063L9.77546 21.8469C9.91124 21.6814 10.1182 21.5916 10.3321 21.6024C10.8593 21.6292 11.3931 21.6428 11.9325 21.6428C13.8421 21.6428 15.6823 21.4717 17.409 21.1546ZM17.1682 19.8432C19.5479 19.4062 21.3494 17.4555 21.6436 15.0196C21.7885 13.82 21.8648 12.5757 21.8648 11.2994C21.8648 10.0232 21.7885 8.77886 21.6436 7.57924C21.3494 5.14341 19.5479 3.19271 17.1682 2.75571C15.5225 2.4535 13.763 2.28939 11.9325 2.28939C10.1019 2.28939 8.34243 2.4535 6.69671 2.75571C4.31702 3.19271 2.51552 5.14341 2.22131 7.57924C2.07642 8.77885 2.00008 10.0232 2.00008 11.2994C2.00008 12.5757 2.07642 13.82 2.22131 15.0196C2.47263 17.1004 3.82561 18.8284 5.69472 19.5586C6.42548 19.8441 7.00705 20.5581 7.00705 21.4544V23.1195L8.74457 21.0013C9.15366 20.5026 9.77121 20.2389 10.3996 20.2708C10.9043 20.2964 11.4156 20.3095 11.9325 20.3095C13.763 20.3095 15.5225 20.1454 17.1682 19.8432Z"
                    fill="#092F46"
                />
            </svg>
        </a>
    );

    return (
        <Layout.Footer className={styles.wrap}>
            <div className={styles.main}>
                <Row type="flex" justify="space-between">
                    <Col md={12} xs={24} sm={24}>
                        <div className="footer_contacts">
                            Організатор: <strong className="name_contact">Валентина Ткаченко</strong> {viberLink}
                            <span className="number_contact">+38 097 239 72 70</span>
                        </div>
                    </Col>
                    <Col md={12} xs={24} sm={24}>
                        <div className="footer-right-block">
                            by
                            <a href="https://brightech.it/">
                                <img src={logo_br} alt="" />
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
        </Layout.Footer>
    );
};

export default Footer;
