import React from 'react';
import {Form, Input} from 'antd/lib';
import {getIn} from 'formik';

const TextArea = ({field, form, label, required, ...rest}: any) => {
    const validationError = getIn(form.errors, field.name);
    const isTouched = Boolean(form.touched[field.name]);
    const isInvalid = Boolean(isTouched && validationError) || Boolean(validationError);
    return (
        <Form.Item
            label={label}
            required={required}
            validateStatus={isInvalid ? 'error' : ''}
            hasFeedback={isInvalid}
            help={isInvalid ? validationError : ''}
        >
            <Input.TextArea {...field} {...rest} />
        </Form.Item>
    );
};

export default TextArea;
