import React from 'react';
import {Row, Col} from 'antd';

import styles from './information.module.scss';
import img1 from './img_1.jpg';
import img2 from './img_2.png';

const EventInfo: React.FC<{giftsCount: number}> = ({giftsCount}) => {
    return (
        <>
            <Row>
                <div className={styles.title}>Мрії, що втілились у життя!</div>
            </Row>
            <Row>
                <Col lg={12}>
                    <Row>
                        <div className={styles.image1Container}>
                            <img className={styles.img1} src={img1} alt="img1" />
                            <div className={styles.img1Border}></div>
                        </div>
                    </Row>
                </Col>
                <Col lg={12}>
                    <Row className={styles.text}>
                        Нижче на фото — <strong>{giftsCount}</strong> дітей, яких вдалося зробити
                        щасливішими&nbsp;у&nbsp;2023&nbsp;році. Їх радість завжди
                        найщиріша!&nbsp;Завдяки&nbsp;кожному,&nbsp;хто здійснив маленьку мрію — купив іграшку,
                        фарби&nbsp;чи&nbsp;омріяну гру — діти продовжують
                        зберігати&nbsp;у&nbsp;своїх&nbsp;серцях&nbsp;віру&nbsp;в&nbsp;диво.
                    </Row>
                    <Row>
                        <div className={styles.image2Container}>
                            <img className={styles.img2} src={img2} alt="img2" />
                            <div className={styles.img2Border}></div>
                        </div>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default EventInfo;
