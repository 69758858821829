import React from 'react';
import {Row} from 'antd';

import ChildrenTitle from 'components/ChildrenTitle';

import CharityEventDescription from './components/CharityEventDescription';
import CharityEventRules from './components/CharityEventRules';

const CharityEventInfo: React.FC = () => {
    return (
        <>
            <Row type="flex" align="middle">
                <CharityEventDescription />
            </Row>
            <div>
                <Row type="flex">
                    <CharityEventRules />
                </Row>
            </div>

            <ChildrenTitle />
        </>
    );
};

export default CharityEventInfo;
