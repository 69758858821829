import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {getIn} from 'formik/dist';
import {Form, Input} from 'antd/lib';
import MaskedInput from 'react-text-mask';

const TextInput = ({
    field,
    form: {errors, touched, setFieldValue},
    label,
    type,
    disabled,
    mask,
    placeholder,
    ...rest
}: any): JSX.Element => {
    const validationError = getIn(errors, field.name);
    const isTouched = Boolean(touched[field.name]);
    const isInvalid = Boolean(isTouched && validationError) || Boolean(validationError);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const trimmedValue = e.target.value.trimStart();
        setFieldValue(field.name, trimmedValue);
    };

    return (
        <Form.Item
            label={label}
            required={true}
            validateStatus={isInvalid ? 'error' : ''}
            hasFeedback={isInvalid}
            help={isInvalid ? validationError : ''}
        >
            {isEmpty(mask) ? (
                <Input
                    {...field}
                    type={type}
                    disabled={disabled}
                    placeholder={placeholder}
                    {...rest}
                    onChange={handleChange}
                />
            ) : (
                <MaskedInput {...field} mask={mask} className="ant-input" placeholder={placeholder} {...rest} />
            )}
        </Form.Item>
    );
};

export default TextInput;
