import React from 'react';
import {Modal, Button} from 'antd';

const BookGiftSuccessModal = ({data, onClose}: any): JSX.Element => {
    const {gift} = data;
    return (
        <Modal
            visible={true}
            closable={false}
            title="Дякую, що стали чарівником!"
            footer={[
                <div key="2" />,
                <Button key="1" color="primary" onClick={onClose} className="custom-btn custom-btn__consent">
                    OK
                </Button>,
            ]}
        >
            <div>
                Ви забронювали подарунок для{' '}
                <strong>
                    {gift.child.name} (№{gift.itemNumber})
                </strong>
                .<br />
                Дякуємо, що ви з нами. Організатор із Вами зв'яжеться, якщо у Вас є питання напишіть нам.
            </div>
        </Modal>
    );
};

export default BookGiftSuccessModal;
