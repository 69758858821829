export const BOOK_MODAL = 'BOOK_MODAL';
export const BOOKING_SUCCESS_MODAL = 'BOOKING_SUCCESS_MODAL';
export const GIFT_BOOKING_CONFIRM_MODAL = 'GIFT_BOOKING_CONFIRM_MODAL';
export const GIFT_CREATION_SUCCESS_MODAL = 'GIFT_CREATION_SUCCESS_MODAL';
export const GIFT_UPDATE_ERROR_MODAL = 'GIFT_UPDATE_ERROR_MODAL';
export const GIFT_CREATION_ERROR_MODAL = 'GIFT_CREATION_ERROR_MODAL';
export const RESERVED_MODAL = 'RESERVED_MODAL';
export const GIFT_MODAL = 'GIFT_MODAL';
export const DELETE_MODAL = 'DELETE_MODAL';
export const CONFIRM_CHANGE_STATUS_TO_FREE_MODAL = 'CONFIRM_CHANGE_STATUS_TO_FREE_MODAL';
export const GIFT_CREATION_DUPLICATE_ERROR_MODAL = 'GIFT_CREATION_DUPLICATE_ERROR_MODAL';

export type ModalNamesType =
    | typeof BOOK_MODAL
    | typeof BOOKING_SUCCESS_MODAL
    | typeof GIFT_BOOKING_CONFIRM_MODAL
    | typeof GIFT_CREATION_SUCCESS_MODAL
    | typeof GIFT_CREATION_ERROR_MODAL
    | typeof GIFT_CREATION_DUPLICATE_ERROR_MODAL
    | typeof GIFT_UPDATE_ERROR_MODAL
    | typeof RESERVED_MODAL
    | typeof GIFT_MODAL
    | typeof DELETE_MODAL
    | typeof CONFIRM_CHANGE_STATUS_TO_FREE_MODAL;
