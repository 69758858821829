import React from 'react';
import {Modal, Button} from 'antd';

const GiftUpdateError = ({onSubmit}: any): JSX.Element => {
    return (
        <Modal
            visible={true}
            closable={false}
            title="Зміни не збережені"
            footer={[
                <div key="2" />,
                <Button key="1" color="primary" className="custom-btn custom-btn__remove" onClick={onSubmit}>
                    OK
                </Button>,
            ]}
        >
            <div>При редагування заявки виникла помилка. Спробуйте змінити пізніше.</div>
        </Modal>
    );
};

export default GiftUpdateError;
