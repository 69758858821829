import React from 'react';
import Gallery from '../Gallery';
import {getPathToPhoto} from 'utils';

type OwnProps = {
    photos: any;
    children: any;
};

const PostedImagesGallery: React.FC<OwnProps> = ({photos, children}) => {
    const pathToImages = photos.map((item: any) => {
        return {
            ...item,
            path: getPathToPhoto(item.path),
        };
    });
    return <Gallery photos={pathToImages}>{children}</Gallery>;
};
export default PostedImagesGallery;
