import React from 'react';

import Gallery from '../Gallery';

type OwnProps = {
    photos: any;
    children: any;
};

const PublicImagesGallery: React.FC<OwnProps> = ({photos, children}) => {
    return <Gallery photos={photos}>{children}</Gallery>;
};

export default PublicImagesGallery;
