import React from 'react';
import {useSelector} from 'react-redux';

import {isUserLoggedIn} from 'app/reducers/commonData/selectors';

import AdminRoleList from './components/AdminRoleList';
import GuestRoleList from './components/GuestRoleList';

const ActiveGifts: React.FC = () => {
    const isCurrentUserLoggedIn = useSelector(isUserLoggedIn);
    return isCurrentUserLoggedIn ? <AdminRoleList /> : <GuestRoleList />;
};

export default ActiveGifts;
