import React from 'react';
import {Modal, Button} from 'antd';

const Delete = ({gift, onClose, onSubmit}: any): JSX.Element => {
    return (
        <Modal
            visible={true}
            closable={false}
            title="Удаление пожелания"
            footer={[
                <Button key="1" color="secondary" className="custom-btn custom-btn__cancel" onClick={onClose}>
                    Скасувати
                </Button>,
                <Button key="2" color="danger" className="custom-btn custom-btn__remove" onClick={onSubmit}>
                    Вилучити
                </Button>,
            ]}
        >
            <div>
                {' '}
                Якщо ви натиснете кнопку <strong>Видалити подарунок для: {gift.child.name}</strong>, всі данні
                зітруться.
                <br />
                Ви впевнені, що хочете зробити?
            </div>
        </Modal>
    );
};

export default Delete;
