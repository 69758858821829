import React from 'react';

import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';

const BreadCrumbs: React.FC = () => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/">Головна</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Реквізити фонду</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default BreadCrumbs;
