import React from 'react';
import {Checkbox} from 'antd';

type OwnProps = {
    checked: boolean;

    onChange(checked: boolean): void;
};

const ConfirmTermsOfConditionsCheckbox: React.FC<OwnProps> = ({checked, onChange}: OwnProps) => {
    const label =
        'Організатор проєкту залишає за собою право підтверджувати, скасовувати, редагувати, видаляти заявки без попереднього обговорення з учасником.';
    return (
        <div>
            <Checkbox checked={checked} onChange={event => onChange(event.target.checked)}>
                {label}
            </Checkbox>
        </div>
    );
};

export default ConfirmTermsOfConditionsCheckbox;
