import React from 'react';
import {Button, Icon} from 'antd';
import 'moment/min/locales';

import StatusesDropDown from './StatusesDropDown';
import {SearchParams} from '../../types';
// import MonthDropDown from '../common/MonthDropDown';

interface OwnProps {
    searchParams: SearchParams;
    onAddNewGift(): void;
    onRefreshGiftsList(): void;
    onSearchByMonth(params: any): void;
    onClearParams(params: any): void;
    onSearchByStatus(params: any): void;
}

const SearchForAdmin = ({
    searchParams,
    onAddNewGift,
    onRefreshGiftsList,
    // onSearchByMonth,
    // onClearParams,
    onSearchByStatus,
}: OwnProps): JSX.Element | null => {
    // const handleChangeMonth = (momentMonth: any): void => {
    //     onSearchByMonth({
    //         month: momentMonth,
    //     });
    // };
    return (
        <div className="filter filter-admin">
            <Button onClick={onAddNewGift} type="primary">
                <Icon type="plus" /> Добавить новое пожелание
            </Button>
            <div className="filter__item">
                <Button className="custom-btn-edit" onClick={onRefreshGiftsList}>
                    Обновить статусы
                </Button>
            </div>
            {/*<div className="filter__item">*/}
            {/*    Показать дни рождения: <MonthDropDown value={searchParams.month} onChange={handleChangeMonth} />*/}
            {/*</div>*/}
            {/*<div onClick={onClearParams} className="filter__item">*/}
            {/*    <span className="filter__item_clear">Очистить фильтры</span>*/}
            {/*</div>*/}
            <div className="filter__item">
                Показать: <StatusesDropDown value={searchParams.status} onChange={onSearchByStatus} />
            </div>
        </div>
    );
};

export default SearchForAdmin;
