import React from 'react';
import {Col} from 'antd';

import img1 from 'images/photo/1.jpg';
import img2 from 'images/photo/2.jpg';
import img3 from 'images/photo/3.jpg';
import img4 from 'images/photo/4.jpg';
import img5 from 'images/photo/5.jpg';
import img6 from 'images/photo/6.jpg';

import PublicImagesGallery from 'components/PublicImagesGallery';

import styles from './rules.module.scss';

const galleryImages = [
    {
        path: `${img1}`,
    },
    {
        path: `${img2}`,
    },
    {
        path: `${img3}`,
    },
    {
        path: `${img4}`,
    },
    {
        path: `${img5}`,
    },
    {
        path: `${img6}`,
    },
];

const CharityEventRules: React.FC = (): JSX.Element => {
    return (
        <>
            <Col lg={24}>
                <h2 className={styles.rules_title}>Як взяти участь</h2>
            </Col>
            <Col lg={12}>
                <div className={styles.list_rules}>
                    <div className={styles.rules}>
                        <span className={styles.number_rules}>01</span>
                        <p>Ознайомтеся зі списком дітей, яким ви можете обрати подарунок.</p>
                    </div>
                    <div className={styles.rules}>
                        <span className={styles.number_rules}>02</span>{' '}
                        <p>
                            Натисніть кнопку «Подарувати» та введіть дані. Далі організатор зв'яжеться із Вами для
                            підтвердження броні.
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <span className={styles.number_rules}>03</span>{' '}
                        <p>
                            Купуйте або замовляйте подарунок та солодощі для обраної дитини в інтернет-магазині та
                            надсилайте нам пакуночок до 5 грудня (у прозорій упаковці чи подарунковому пакеті).
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <span className={styles.number_rules}>04</span>{' '}
                        <p>
                            <strong>Реквізити відправки подарунка:</strong>
                            <br />
                            м. Миколаїв, Нова Пошта, Відділення #6 <br />
                            Отримувач: Ткаченко Валентина, тел. +38 097 239 7270 <br />
                            просимо оплачувати посилки.
                        </p>
                    </div>
                </div>
            </Col>
            <Col lg={12}>
                <div className={styles.list_rules}>
                    <div className={styles.rules}>
                        <p>
                            Фото та звіт можна знайти після 28 грудня на сайті — напроти обраної вами дитини у розділі
                            «Звіт».
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <p>
                            Щороку не всі діти отримують подарунок та солодощі, але абсолютно всі — вірять та чекають.
                            За допомогою цієї акції ви можете перерахувати кошти на картку ПриватБанку.
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <p>
                            ПриватБанк <strong>4149 6293 6589 5937</strong> Ткаченко Валентина Валентинівна
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <p>
                            MonoBank <strong>4441 1111 6323 3616</strong> Ткаченко Валентина Валентинівна
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <p>
                            <PublicImagesGallery photos={galleryImages}>Фото</PublicImagesGallery> та{' '}
                            <a
                                href="https://www.youtube.com/channel/UCQuQPnQVqSwly5nJywAGQDQ"
                                target="_blank"
                                className={styles.link}
                            >
                                відео
                            </a>{' '}
                            з торішнього свята Дня Святого Миколая.
                        </p>
                    </div>
                    <div className={styles.rules}>
                        <p>
                            За останні 5 років ми подарували свято <strong>2856 дітям</strong> і навіть зараз, коли в
                            Україні триває війна, ми продовжуємо проект. Долучайся до добрих справ, подаруй дітям свято!
                        </p>
                    </div>
                </div>
            </Col>
        </>
    );
};
export default CharityEventRules;
