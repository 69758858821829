import React from 'react';
import {Modal, Button} from 'antd';

const ReservedGiftWarning = ({onSubmit}: any) => {
    return (
        <Modal
            visible={true}
            closable={false}
            title="Бронювання неможливе"
            footer={[
                <Button color="secondary" onClick={onSubmit}>
                    OK
                </Button>,
            ]}
        >
            <div>Цей подарунок був заброньований іншою людиною</div>
        </Modal>
    );
};

export default ReservedGiftWarning;
