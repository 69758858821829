import {Gift} from 'types';

export default function(createParams: Gift): Gift {
    return {
        ...createParams,
        child: {
            ...createParams.child,
            name: createParams.child.name.trimEnd().replace(/\s{2,}/g, ' '),
        },
    };
}
