import React from 'react';
import {Modal, Button} from 'antd';

const BookGiftConfirmModal = ({data, onSubmit, onClose}: any): JSX.Element => {
    const {gift, personWhoBooked} = data;
    const title = `Бронь подарунку для ${gift.child.name}`;
    return (
        <Modal
            visible={true}
            closable={false}
            title={title}
            footer={[
                <Button key="2" color="primary" onClick={onClose} className="custom-btn custom-btn__cancel">
                    Скасувати
                </Button>,
                <Button key="1" color="primary" onClick={onSubmit} className="custom-btn custom-btn__consent">
                    Підтвердити
                </Button>,
            ]}
        >
            <div>
                {' '}
                {personWhoBooked.name}
                <br />
                {personWhoBooked.phone}
            </div>
        </Modal>
    );
};

export default BookGiftConfirmModal;
