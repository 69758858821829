import React, {useCallback} from 'react';
import {Layout, Menu} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import {ACTIVE_GIFTS_PAGE, REALIZED_GIFTS_PAGE, REQUISITES_GIFTS_PAGE} from 'router/pages';
import * as appActions from 'app/actions';
import {isUserLoggedIn} from 'app/reducers/commonData/selectors';
import LoaderSpinner from 'components/LoaderSpinner';
import {useUserRole} from 'hooks/useUserRole';

import Logo from 'images/icon/logo.svg';

import styles from './header.module.scss';

const pagesKeys: Record<string, string> = {
    [REQUISITES_GIFTS_PAGE]: '1',
    [ACTIVE_GIFTS_PAGE]: '2',
    [REALIZED_GIFTS_PAGE]: '3',
};

const Header = (): JSX.Element => {
    const {isGuest} = useUserRole();
    const params = useLocation();
    const currentPageKey = pagesKeys[params.pathname];
    const facebookLink = (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a className="social-link" href="https://www.facebook.com/groups/197762237039265" target="_blank">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.4674 12.0002C23.4674 18.4435 18.244 23.6668 11.8007 23.6668C5.35738 23.6668 0.134033 18.4435 0.134033 12.0002C0.134033 5.55684 5.35738 0.333496 11.8007 0.333496C18.244 0.333496 23.4674 5.55684 23.4674 12.0002ZM10.4304 22.3107V17.4742H7.85829C7.82147 17.4742 7.79163 17.4444 7.79163 17.4076V14.0766C7.79163 14.0398 7.82147 14.0099 7.85829 14.0099H10.4304V12.1746C10.4304 9.18087 13.4688 8.12208 14.8212 8.12208H16.7675C16.8043 8.12208 16.8342 8.15193 16.8342 8.18875V11.1623C16.8342 11.1991 16.8043 11.229 16.7675 11.229H15.5554C15.0554 11.229 13.8704 11.691 13.8704 12.5807V14.0099H16.7673C16.8071 14.0099 16.8381 14.0447 16.8335 14.0843L16.4438 17.4153C16.4399 17.4489 16.4114 17.4742 16.3776 17.4742H13.8704V22.1942C18.6226 21.2346 22.2007 17.0352 22.2007 12.0002C22.2007 6.2564 17.5445 1.60016 11.8007 1.60016C6.05694 1.60016 1.4007 6.2564 1.4007 12.0002C1.4007 17.2794 5.3343 21.64 10.4304 22.3107ZM11.4971 22.2958V16.4076H8.85829V15.0766H11.4971V12.1746C11.4971 11.1018 12.0181 10.3896 12.7233 9.90446C13.4703 9.39049 14.3564 9.18875 14.8212 9.18875H15.7675V10.1623C15.1207 10.1623 14.5079 10.3204 13.9556 10.6695C13.4551 10.9857 12.8037 11.608 12.8037 12.5807V15.0766H15.6435L15.4878 16.4076H12.8037V22.2958H11.4971Z"
                    fill="#092F46"
                />
            </svg>
        </a>
    );

    const instaLink = (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a className="social-link" href="https://instagram.com/dobrota_ta_dovira?igshid=140j7zzmzvo77" target="_blank">
            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.548 15.6668C14.1253 15.6668 16.2147 13.5775 16.2147 11.0002C16.2147 8.42283 14.1253 6.3335 11.548 6.3335C8.97069 6.3335 6.88135 8.42283 6.88135 11.0002C6.88135 13.5775 8.97069 15.6668 11.548 15.6668ZM11.548 14.3335C13.389 14.3335 14.8813 12.8411 14.8813 11.0002C14.8813 9.15921 13.389 7.66683 11.548 7.66683C9.70707 7.66683 8.21468 9.15921 8.21468 11.0002C8.21468 12.8411 9.70707 14.3335 11.548 14.3335Z"
                    fill="#092F46"
                />
                <path
                    d="M17.548 6.3335C17.9162 6.3335 18.2147 6.03502 18.2147 5.66683C18.2147 5.29864 17.9162 5.00016 17.548 5.00016C17.1798 5.00016 16.8813 5.29864 16.8813 5.66683C16.8813 6.03502 17.1798 6.3335 17.548 6.3335Z"
                    fill="#092F46"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.88135 0.333496C3.56764 0.333496 0.881348 3.01979 0.881348 6.3335V15.6668C0.881348 18.9805 3.56764 21.6668 6.88135 21.6668H16.2147C19.5284 21.6668 22.2147 18.9805 22.2147 15.6668V6.3335C22.2147 3.01979 19.5284 0.333496 16.2147 0.333496H6.88135ZM16.2147 1.66683H6.88135C4.30402 1.66683 2.21468 3.75617 2.21468 6.3335V15.6668C2.21468 18.2442 4.30402 20.3335 6.88135 20.3335H16.2147C18.792 20.3335 20.8813 18.2442 20.8813 15.6668V6.3335C20.8813 3.75617 18.792 1.66683 16.2147 1.66683Z"
                    fill="#092F46"
                />
            </svg>
        </a>
    );
    const isLoggedInUser = useSelector(isUserLoggedIn);
    const dispatch = useDispatch();
    const logOutHandler = useCallback(() => dispatch(appActions.logOut()), [dispatch]);
    return (
        <>
            <LoaderSpinner />
            <Layout.Header className={styles.wrap}>
                <div className={styles.item}>
                    <div className="menu__left">
                        <Menu mode="horizontal">
                            <Menu.Item key="1">
                                <Link to="/">
                                    <img alt={`ОБФ "Доброта та Довіра"`} srcSet={Logo} />
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </div>
                    <div className="menu__center">
                        <Menu
                            defaultSelectedKeys={[currentPageKey]}
                            mode="horizontal"
                            overflowedIndicator={
                                <div className="menu-btn">
                                    <div className="menu-btn">
                                        <div className="menu-btn__burger" />
                                    </div>
                                </div>
                            }
                        >
                            <Menu.Item key="0"></Menu.Item>
                            <Menu.Item key="1">
                                <Link to={REQUISITES_GIFTS_PAGE}>Реквізити</Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to={ACTIVE_GIFTS_PAGE}>Діти</Link>
                            </Menu.Item>
                            {isGuest && (
                                <Menu.Item key="3">
                                    <Link to={REALIZED_GIFTS_PAGE}>Втілені мрії</Link>
                                </Menu.Item>
                            )}
                        </Menu>
                    </div>

                    <div className="menu__right">
                        <Menu mode="horizontal">
                            <Menu.Item key="4">
                                <a href="tel:+380972397270">
                                    <strong>+38 097 239 72 70</strong>
                                </a>
                            </Menu.Item>
                            <Menu.Item key="5">{facebookLink}</Menu.Item>
                            <Menu.Item key="6">{instaLink}</Menu.Item>
                            {isLoggedInUser && (
                                <Menu.Item key="7">
                                    <Link onClick={logOutHandler} to="#">
                                        Logout
                                    </Link>
                                </Menu.Item>
                            )}
                        </Menu>
                    </div>
                </div>
            </Layout.Header>
        </>
    );
};

export default Header;
