import React from 'react';
import {Col, Row} from 'antd';

import girl from 'images/photo/kids.jpg';

import styles from './stockInformation.module.scss';

const CharityEventDescription: React.FC = () => {
    return (
        <>
            <Col lg={16}>
                <h2 className={styles.title}>
                    Благодійна акція <br /> "Стань Чарівником"
                </h2>
                <Row className={styles.block}>
                    <Col lg={10}>
                        <iframe
                            height="490"
                            src="https://www.youtube.com/embed/Tj3vO6_DIH0?si=DLOkPmpKXv96pjE2"
                            title="Доброта та довіра"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className={styles.video}
                        />
                    </Col>
                    <Col lg={13} offset={1}>
                        <div className={styles.information_card}>
                            <p>Щороку фонд «Доброта та довіра» вітає особливих діток із Днем Святого Миколая!</p>
                            <p>
                                Діти для святого Миколая – найрадісніші і найсвятіші створіння, які завжди потребують
                                захисту, уваги та лагідного ставлення. Для них він і опікун, і заступник у біді, і
                                привітний дідусь, що гладить по голівці, радує подарунками.
                            </p>

                            <p>
                                Нижче викладено фотографії листів дітлахів, які вірять у диво та добро. Вони мають різні
                                життєві історії: діти з інвалідністю, сироти, онкохворі... Але всіх їх поєднує віра у
                                диво. Пропонуємо і вам стати для когось феєю чи чарівником!
                            </p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col lg={7} offset={1}>
                <img className={styles.photo} src={girl} alt="girl" />
            </Col>
        </>
    );
};

export default CharityEventDescription;
