import moment from 'moment/moment';
import {Gift} from 'types/index';
import * as statuses from 'constants/statuses';
import * as types from '../actionTypes';

interface GiftsState {
    giftsList: Gift[];
    pagination: {
        page: number;
        size: number;
        itemsCount?: number;
        pagesCount: number;
    };
    search: {
        status: string | undefined;
        month: string | undefined;
    };
}

const currentMonth = moment().format('M');

const defaultState: GiftsState = {
    giftsList: [],
    pagination: {
        page: 0,
        size: 20,
        pagesCount: 0,
    },
    search: {
        status: statuses.GIFT_STATUS_ALL,
        month: currentMonth,
    },
};

export default function giftsReducer(state = defaultState, action: types.GiftsActionTypes): GiftsState {
    switch (action.type) {
        case types.LIST_GIFTS_RECEIVED: {
            const {gifts, pagination} = action.payload;
            return {
                ...state,
                giftsList: gifts,
                pagination,
            };
        }

        case types.GIFT_CREATED: {
            const createdGift = action.payload;
            return {
                ...state,
                giftsList: [...state.giftsList, createdGift],
            };
        }

        case types.SELECTED_GIFT_UPDATED: {
            const updatedGift = action.payload;
            return {
                ...state,
                giftsList: state.giftsList.map(item => {
                    if (item._id === updatedGift._id) {
                        return updatedGift;
                    }

                    return item;
                }),
            };
        }

        case types.SELECTED_GIFT_DELETED: {
            const deletedGift = action.payload;
            return {
                ...state,
                giftsList: state.giftsList.filter(g => g._id !== deletedGift._id),
            };
        }

        case types.SELECTED_GIFT_BOOKED: {
            const bookedGift = action.payload;
            return {
                ...state,
                giftsList: state.giftsList.map(item => {
                    if (item._id === bookedGift._id) {
                        return bookedGift;
                    }

                    return item;
                }),
            };
        }

        case types.SELECTED_SEARCH_PARAMS_CHANGED: {
            const selectedSearchParams = action.payload;
            return {
                ...state,
                search: {
                    ...state.search,
                    ...selectedSearchParams,
                },
            };
        }

        case types.SELECTED_SEARCH_PARAMS_CLEARED: {
            return {
                ...state,
                search: defaultState.search,
                pagination: defaultState.pagination,
            };
        }

        case types.SELECTED_PAGINATION_CHANGED: {
            const {nextPage} = action.payload;
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: nextPage,
                },
            };
        }

        default:
            return state;
    }
}
