import React from 'react';
import {Modal} from 'antd';

import {Gift} from 'types/index';

import Form from './Form';
import styles from './booking.module.scss';

interface OwnProps {
    gift: Gift;
    onClose(): void;
    onSubmit(fields: any): void;
}

const Booking = ({gift, onClose, onSubmit}: OwnProps): JSX.Element => {
    return (
        <Modal
            visible={true}
            closable={false}
            footer={null}
            className={styles.modal}
            title={`Забронювати подарунок для ${gift.child.name}`}
        >
            <div>Заповніть Ваші дані, щоб ми могли зв'язатися з Вами</div>
            <Form onClose={onClose} onSubmit={onSubmit} />
        </Modal>
    );
};

export default Booking;
