import React from 'react';
import {Route, Switch} from 'react-router-dom';

import IndexPage from 'pages/Index';
import LoginPage from 'pages/Login';
import GiftsPage from 'pages/Gifts';
import RequisitesPage from 'pages/Requisites';
import NotFoundPage from 'pages/NotFound';
import {useUserRole} from 'hooks/useUserRole';

import {userIsNotAuthenticatedRedir, userIsAuthenticatedRedir} from './auth';
import {ACTIVE_GIFTS_PAGE, REALIZED_GIFTS_PAGE, REQUISITES_GIFTS_PAGE} from './pages';

const LoginPageProtected = userIsNotAuthenticatedRedir(LoginPage);
const GiftsPageProtected = userIsAuthenticatedRedir(GiftsPage);

export default () => {
    const {isGuest} = useUserRole();
    return (
        <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path={ACTIVE_GIFTS_PAGE} render={() => <GiftsPage isRealized={false} />} />

            {isGuest && <Route exact path={REALIZED_GIFTS_PAGE} render={() => <GiftsPage isRealized={true} />} />}

            <Route exact path="/admin" component={GiftsPageProtected} />

            <Route exact path={REQUISITES_GIFTS_PAGE} component={RequisitesPage} />

            <Route exact path="/login" component={LoginPageProtected} />

            <Route component={NotFoundPage} />
        </Switch>
    );
};
