import React from 'react';

import BreadCrumbs from './components/BreadCrumbs';
import styles from './requisites.module.scss';

const Requisites: React.FC = () => {
    return (
        <div className={styles.item}>
            <BreadCrumbs />
            <div className={styles.main}>
                <div className={styles.heading}>Реквізити фонду</div>
                <div className="requisites-privat">
                    <h3>
                        <strong>ПриватБанк</strong>
                    </h3>
                    <div className="requisites-privat__card">
                        <strong>4149 6293 6589 5937</strong>
                    </div>
                    <p>(Карта оформлена на Ткаченко Валентину Валентинівну, засновницю фонду)</p>
                </div>

                <div className="requisites-privat">
                    <h3>
                        <strong>MonoBank</strong>
                    </h3>
                    <div className="requisites-privat__card">
                        <strong>4441 1111 6323 3616</strong>
                    </div>
                    <p>(Карта оформлена на Ткаченко Валентину Валентинівну, засновницю фонду)</p>
                </div>
                <div>
                    <p>
                        <strong>Призначення платежу:</strong>
                        <br />
                        <strong>Поповнення рахунку ТКАЧЕНКО ВАЛЕНТИНА ВАЛЕНТИНІВНА</strong>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Requisites;
